<template>
  <layout style="max-height: 90vh; overflow: auto;" :title="$route.name">
    <div
      class="expande-horizontal centraliza wrap pa-0 pb-3"
      style="min-height: 200px; align-items: flex-start; justify-content: flex-start;"
    >
      <v-flex xs12 md3>
        <ProductItem
          :priceless="true"
          :fullscreen="true"
          v-if="getPurchase.purchase_type === 'product'"
          :product="getPurchase"
        />
        <CareerItem
          :priceless="true"
          :fullscreen="true"
          v-if="getPurchase.purchase_type === 'career'"
          :career="getPurchase"
        />
        <InvestmentItem
          :priceless="true"
          :fullscreen="true"
          v-if="getPurchase.purchase_type === 'investment'"
          :investment="getPurchase"
          class="mb-3"
        />
        <MonthlyPaymentItem
          :priceless="true"
          :fullscreen="true"
          v-if="getPurchase.purchase_type === 'monthly_payment'"
          :investment="getPurchase"
          class="mb-3"
        />
      </v-flex>

      <v-flex
        :class="{
          'pa-3 pt-0': $vuetify.breakpoint.smAndDown,
          'pt-3': $vuetify.breakpoint.lgAndUp
        }"
        xs12
        md9
      >
        <v-card
          color="#f2f2f2"
          style="border-radius: 12px;"
          class="expande-horizontal wrap"
        >
          <div class="expande-horizontal wrap py-0 pb-0">
            <v-flex xs12>
              <v-form>
                <v-flex class="px-2 pt-2" xs12>
                  <span
                    style="font-size: 16pt;"
                    class="py-3 fonte-bold text-uppercase dark--text"
                  >
                    1. ESCOLHA O MÉTODO DE PAGAMENTO
                  </span>
                  <v-select
                    class="fonte-bold mt-3"
                    label="ESCOLHA O MÉTODO DE PAGAMENTO"
                    persistent-hint
                    outlined
                    :items="getPaymentMethods"
                    v-model="getPurchase.paymentMethod"
                    @input="$store.commit('setPaymentMethod', $event)"
                    :rules="[v => !!v || 'Preencha aqui']"
                    placeholder="ex: Saldo da Conta"
                    type="text"
                    return-object
                    offset-y
                    bottom
                    item-text="name"
                  ></v-select>
                </v-flex>
                <v-flex v-if="getPurchase.paymentMethod" class="px-2" xs12>
                  <span
                    style="font-size: 16pt;"
                    class="py-3 fonte-bold text-uppercase dark--text"
                  >
                    2. INSTRUÇÃO DO PAGAMENTO PARA
                    <v-icon class="mx-1" color="grey">mdi-chevron-right</v-icon>
                    {{ getPurchase.paymentMethod.description }}
                  </span>
                  <div class="expande-horizontal wrap">
                    <v-flex class="pt-3" xs12>
                      <v-textarea
                        height="100"
                        readonly
                        outlined
                        dense
                        class="fonte-bold"
                        persistent-hint
                        :items="getPaymentMethods"
                        v-model="getPurchase.paymentMethod.info"
                        @input="$store.commit('setPaymentMethod', $event)"
                        placeholder="ex: Não há informações adicionais para este método de pagamento!"
                        type="text"
                        return-object
                        item-text="name"
                      ></v-textarea>
                    </v-flex>
                    <v-flex
                      v-if="getPaymentMethod.name !== 'SALDO DA CONTA'"
                      xs12
                    >
                      <div class="expande-horizontal column">
                        <span
                          style="font-size: 16pt;"
                          class="py-3 fonte-bold text-uppercase black--text"
                        >
                          3. Comprovante de pagamento
                        </span>
                        <SendArchive />
                      </div>
                    </v-flex>
                  </div>
                </v-flex>
              </v-form>
            </v-flex>
            <!-- <v-btn small block outlined :color="$theme.primary" @click="enableModalSendArchive">ANEXAR COMPROVANTE - {{ getLinks.length }} ANEXO(S)</v-btn> -->
          </div>

          <div
            class="expande-horizontal elevation-0 centraliza pb-3 py-0"
            :class="{
              'px-3 mt-12': $vuetify.breakpoint.smAndDown
            }"
          >
            <v-btn
              large
              @click="validatePurchase"
              :block="$vuetify.breakpoint.smAndDown"
              :class="{}"
              color="green"
            >
              <div
                v-if="getPurchase.purchase_type === 'product'"
                class="expande-horizontal centraliza "
              >
                <span class="fonte-bold" style="font-size: 14pt;">
                  Finalizar Compra -
                </span>
                <v-avatar class="mx-2" size="27">
                  <v-img :src="$theme.token_img"></v-img>
                </v-avatar>
                {{ getPurchase.token_price }}
              </div>
              <div
                v-else
                class="expande-horizontal centraliza white--text mx-2 fonte-bold"
                style="font-size: 14pt;"
              >
                Finalizar Compra - $ {{ getPricePurchase() }}
                {{
                  getPurchase.purchase_type === "product" ||
                  getPurchase.purchase_type === "career"
                    ? ""
                    : ""
                }}
              </div>
            </v-btn>
          </div>
        </v-card>
      </v-flex>
    </div>
    <ModalViewAddress />
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductItem from "@/apps/4#product/components/ProductItem";
import CareerItem from "@/apps/5#careers/components/careerItem";
import InvestmentItem from "@/apps/14#investments/components/InvestmentItem";
import ModalViewAddress from "@/apps/9#addresses/components/ModalViewAddress";
import MonthlyPaymentItem from "./components/MonthlyPaymentItem";
import SendArchive from "@/apps/shared/components/ModalSendArchive";
export default {
  data() {
    return {
      retirada: false,
      entrega: false
    };
  },
  components: {
    ProductItem,
    CareerItem,
    InvestmentItem,
    ModalViewAddress,
    SendArchive,
    MonthlyPaymentItem
  },
  computed: {
    ...mapGetters([
      "getModalViewPurchase",
      "getPurchase",
      "getPaymentMethods",
      "getPaymentMethod",
      "getAddresses",
      "getLinks"
    ])
  },
  methods: {
    getPricePurchase() {
      let price = parseFloat(this.getPurchase.price);

      if (this.getPurchase.purchase_type === "investment") {
        price += parseFloat(this.getPurchase.unilevel_price) || 0;
      }

      if (this.getPurchase.fee) {
        price += parseInt(this.getPurchase.fee);
      }

      return price;
    },
    ...mapActions([
      "createPurchase",
      "createGlobalMessage",
      "cancelPurchase",
      "listPaymentMethods",
      "listAddresses",
      "enableModalSendArchive",
      "createConfirmAction",
      "openModalViewAddress"
    ]),
    selectModalityReceivment(modality) {
      if (modality === "retirada") {
        this.retirada = true;
        this.entrega = false;
        this.getPurchase.withdraw = true;
      }
      if (modality === "entrega") {
        this.retirada = false;
        this.entrega = true;
        this.getPurchase.withdraw = false;
      }
    },
    validatePurchase() {
      if (this.$store.getters.getLinks.length == 0) {
        this.createGlobalMessage({
          opened: true,
          message: "Anexe o comprovante de pagamento.",
          color: "red",
          timeout: 3000
        });
        return;
      }
      if (this.getPurchase.purchase_type === "career") {
        this.createConfirmAction({
          opened: true,
          icon: "mdi-check",
          color: "green",
          message: "Confirma a finalização da carreira?",
          action: "createPurchase",
          action_value: "false"
        });
        return;
      }
      this.createConfirmAction({
        opened: true,
        icon: "mdi-check",
        color: "green",
        message: "Confirma a finalização da compra?",
        action: "createPurchase",
        action_value: "false"
      });
    },
    created() {
      this.listPaymentMethods();
      this.listAddresses();
      this.$store.commit('setLinks', []);
    }
  }
};
</script>
