<template>
  <v-flex xs12 :md3="!fullscreen" class="pa-3">
    <v-card elevation="24" style="border-radius: 12px;">
      <v-carousel
        :continuous="false"
        :cycle="true"
        :show-arrows="true"
        delimiter-icon="mdi-minus"
        height="250"
      >
        <v-carousel-item v-for="(slide, i) in investment.images" :key="i">
          <v-sheet height="100%" tile>
            <v-row class="fill-height" align="center" justify="center">
              <div class="display-3">
                <v-img max-height="260" contain :src="slide"></v-img>
              </div>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <v-list three-line dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="fonte" style="font-size: 12pt;">
              <span class="fonte font-weight-bold">
                {{ investment.title }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-text-field
                v-model="investment.price"
                filled
                prefix="$"
                autofocus
                v-mask="['##########']"
                label="Informe o valor do aporte"
              ></v-text-field>
            </v-list-item-subtitle>
            <!-- <v-list-item-title class="fonte orange--text">
              Ganho total:
              {{ investment.total_percent_gain }}%</v-list-item-title
            > -->
            <!-- <v-list-item-subtitle class="fonte font-weight-thin">{{
              investment.description
            }}</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["investment", "fullscreen", "priceless"],
  methods: {
    ...mapActions(["openPurchase"])
  }
};
</script>
